/* eslint-disable no-unused-vars */
import { blue, green, grey, indigo, red, teal } from '@material-ui/core/colors';

// const inverseLocadLogoPath = "/static/img/logos/logo-inverse.png";
// const usualLocadLogoPath = "/static/img/logos/logo.png";
const inverseLocadLogoPath = '/static/img/logos/brand_logo.svg';
const usualLocadLogoPath = '/static/img/logos/brand_logo.svg';
const blackEtailyLogoPath = '/static/img/logos/etaily-black.png';
const whiteEtailyLogoPath = '/static/img/logos/etaily-white.png';
const imagePlaceholder = '/static/img/placeholder.png';
const locadMiniLogoPath = '/static/img/logos/locad_grey_mini.svg';
const partnerhubMiniLogoPath = '/static/img/logos/logo_partnerhub_mini_grey.svg';

const blueVariant = {
  name: 'Blue',
  palette: {
    primary: {
      main: blue[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: blue[600],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: blue[700],
    header: {
      color: '#FFF',
      background: blue[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: blue[800],
      online: {
        background: '#FFF',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#F7F9FC',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
    },
    imagePlaceholder,
  },
};

const greenVariant = {
  name: 'Green',
  palette: {
    primary: {
      main: green[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: green[500],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: green[500],
    },
  },
  sidebar: {
    color: '#FFF',
    background: green[700],
    header: {
      color: '#FFF',
      background: green[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: green[800],
      online: {
        background: '#FFF',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#F9F9FC',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
    },
    imagePlaceholder,
  },
};

const indigoVariant = {
  name: 'Indigo',
  palette: {
    primary: {
      main: indigo[600],
      contrastText: '#FFF',
    },
    secondary: {
      main: indigo[400],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: indigo[500],
    },
  },
  sidebar: {
    color: '#FFF',
    background: indigo[600],
    header: {
      color: '#FFF',
      background: indigo[700],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: indigo[700],
      online: {
        background: '#FFF',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#F9F9FC',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
    },
    imagePlaceholder,
  },
};

const tealVariant = {
  name: 'Teal',
  palette: {
    primary: {
      main: teal[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: teal[600],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: teal[600],
    },
  },
  sidebar: {
    color: '#FFF',
    background: teal[700],
    header: {
      color: '#FFF',
      background: teal[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: teal[800],
      online: {
        background: '#FFF',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#F7F9FC',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
    },
    imagePlaceholder,
  },
};

const lightVariant = {
  name: 'Light',
  palette: {
    primary: {
      main: blue[800],
      contrastText: '#FFF',
    },
    secondary: {
      main: blue[600],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[200],
    background: blue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: '#FFF',
    header: {
      color: '#FFF',
      background: blue[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: grey[900],
      background: grey[100],
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 600,
    },
    badge: {
      color: '#FFF',
      background: green[600],
    },
  },
  body: {
    background: '#F7F9FC',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
    },
    imagePlaceholder,
  },
};

const darkVariant = {
  name: 'Dark',
  palette: {
    primary: {
      main: blue[700],
      contrastText: '#FFF',
    },
    secondary: {
      main: blue[500],
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFFFFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: grey[200],
    background: '#1B2430',
    header: {
      color: grey[200],
      background: '#232f3e',
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#232f3e',
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#FFF',
      background: blue[500],
    },
  },
  body: {
    background: '#F7F9FC',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
    },
    imagePlaceholder,
  },
};

const locadNewBrandingVariant = {
  name: 'Locad',
  palette: {
    primary: {
      main: '#060A06',
      light: '#3D3D3D',
      dark: '#060A06',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#50D860',
      light: '#C8FFCF',
      dark: '#94FFA1',
      contrastText: '#1A1A1A',
    },
    info: {
      main: '#847BFF',
      light: '#C2BDFF',
      dark: '#5A50E2',
      contrastText: '#FFF',
    },
    warning: {
      main: '#F7A12C',
      light: '#FFC672',
      dark: '#FF8202',
      contrastText: '#FFF',
    },
    success: {
      main: '#50D860',
      light: '#C8FFCF',
      dark: '#94FFA1',
      contrastText: '#FFF',
    },
    error: {
      main: '#FC4F59',
      light: '#FF8884',
      dark: '#EE212E',
      contrastText: '#FFF',
    },
    disabled: {
      main: '#A4AFAF',
      light: '#CFDCDC',
      dark: '#6D7474',
      contrastText: '#F0F6F4',
    },
    background: {
      main: '#CFDCDC',
      light: '#F0F6F4',
      dark: '#A4AFAF',
      white: '#E5E5E5',
      // one more for background ECF1F1
    },
    pieChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
    },
    barChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
      color14: '#EE212E ',
    },
  },
  header: {
    color: '#FFFFFF',
    background: '#060A06',
    search: {
      color: '#FFFFFF',
    },
    indicator: {
      background: '#2D0065',
    },
  },
  sidebar: {
    color: '#FFFFFF',
    background: '#060A06',
    header: {
      color: '#FFFFFF',
      background: '#060A06',
      brand: {
        color: '#62408C',
      },
    },
    footer: {
      color: '#FFFFFF',
      background: '#1A1A1A',
      online: {
        background: '#42D48B',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#FFF',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
      darkMini: locadMiniLogoPath,
    },
    imagePlaceholder,
    login: {
      btnText: '#2C0065',
      layoutBg: '#1A1A1A',
    },
  },
};

const etailyVariant = {
  name: 'Etaily',
  palette: {
    primary: {
      main: '#14243C',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#14243C',
      contrastText: '#FFF',
    },
    info: {
      main: '#2C4456',
      contrastText: '#FFF',
    },
    warning: {
      main: '#F7A12C',
      light: '#FFC672',
      dark: '#FF8202',
      contrastText: '#FFF',
    },
    success: {
      main: '#42D48B',
      light: '#8DE3B8',
      dark: '#00B55B',
      contrastText: '#FFF',
    },
    error: {
      main: '#ED3D40',
      light: '#FF857C',
      dark: '#CB1B1B',
      contrastText: '#FFF',
    },
    disabled: {
      main: '#A4AFAF',
      light: '#CFDCDC',
      dark: '#6D7474',
      contrastText: '#F0F6F4',
    },
    background: {
      main: '#CFDCDC',
      light: '#F0F6F4',
      dark: '#A4AFAF',
      white: '#E5E5E5',
      // one more for background ECF1F1
    },
    pieChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
    },
    barChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
    },
  },
  header: {
    color: '#FFFFFF',
    background: '#2C4456',
    search: {
      color: '#FFFFFF',
    },
    indicator: {
      background: '#000000',
    },
  },
  sidebar: {
    color: '#FFFFFF',
    background: '#14243C',
    header: {
      color: '#FFFFFF',
      background: '#14243C',
      brand: {
        color: '#14243C',
      },
    },
    footer: {
      color: '#FFFFFF',
      background: '#14243C',
      online: {
        background: '#14243C',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#F7F7FC',
    logos: {
      white: whiteEtailyLogoPath,
      dark: whiteEtailyLogoPath,
      darkMini: whiteEtailyLogoPath,
    },
    imagePlaceholder,
  },
};

const variants = [
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant,
  tealVariant,
  locadNewBrandingVariant,
  etailyVariant,
];

export default variants;
