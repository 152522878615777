import React from 'react';
import { SvgIcon } from '@material-ui/core';

function InboundSVG(props) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="34" height="34" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M8.5 29C8.1 29 7.75 28.8667 7.45 28.6C7.15 28.3333 7 28.0222 7 27.6667V14.3333C7 13.9778 7.15 13.6667 7.45 13.4C7.75 13.1333 8.1 13 8.5 13H23.5C23.9 13 24.25 13.1333 24.55 13.4C24.85 13.6667 25 13.9778 25 14.3333V27.6667C25 28.0222 24.85 28.3333 24.55 28.6C24.25 28.8667 23.9 29 23.5 29H8.5ZM11 25.4444H16V23.6667H11V25.4444ZM12 21.6444L16 19.8667L20 21.6444V14.3333H12V21.6444Z" />
      <path d="M8.87266 9.22228L8.19351 8.52653L9.19226 7.48811H3V6.512H9.20225L8.19351 5.47357L8.86267 4.77783L11 7.00005L8.87266 9.22228Z" />
      <path d="M16.0008 6.9C16.1815 6.9 16.3359 6.83137 16.4641 6.69411C16.5922 6.55686 16.6563 6.39186 16.6563 6.19911C16.6563 6.00637 16.5919 5.84167 16.4632 5.705C16.3346 5.56833 16.1799 5.5 15.9992 5.5C15.8185 5.5 15.6641 5.56863 15.5359 5.70589C15.4078 5.84314 15.3438 6.00814 15.3438 6.20089C15.3438 6.39363 15.4081 6.55833 15.5368 6.695C15.6654 6.83167 15.8201 6.9 16.0008 6.9ZM16 11C14.9937 10.0867 14.2422 9.23833 13.7453 8.455C13.2484 7.67167 13 6.94667 13 6.28C13 5.28 13.3016 4.48333 13.9047 3.89C14.5078 3.29667 15.2062 3 16 3C16.7938 3 17.4922 3.29667 18.0953 3.89C18.6984 4.48333 19 5.28 19 6.28C19 6.94667 18.7516 7.67167 18.2547 8.455C17.7578 9.23833 17.0062 10.0867 16 11Z" />
      <circle cx="16" cy="6.22217" r="1" fill="#060A06" />
      <path d="M23.1273 9.22228L23.8065 8.52653L22.8077 7.48811H29V6.512H22.7978L23.8065 5.47357L23.1373 4.77783L21 7.00005L23.1273 9.22228Z" />
      {/* </svg> */}
    </SvgIcon>
  );
}
export default InboundSVG;
