import React from "react";
import styled from "styled-components";
import {
  Box,
  Typography,
  Grid as MuiGrid,
  Button as MuiButton,
  Divider,
  withTheme,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { PropTypes } from "prop-types";

export const FormControl = styled(Box)`
  width: "75%";
  margin-top: 34px;
`;

export const ShopifyFormControl = styled(Box)`
  width: 100%;
`;

export const SecondaryMUIButton = styled(MuiButton)`
  min-width: 164px;
  min-height: 40px;
  text-align: center;
  border: 0.1rem solid var(--colors-primary);
  background-color: var(--colors-secondary-hover);
  :disabled {
    background-color: var(--color-hover-charcol) !important;
    color: var(--colors-contrast-text-primary) !important;
  }
  :hover {
    background-color: var(--colors-primary);
    color: var(--colors-contrast-text-primary);
    border: 0.1rem solid var(--colors-secondary);
  }
`;

export const AuthSecondaryMUIButton = styled(MuiButton)`
  text-align: center;
  min-width: 164px;
  min-height: 40px;
  border: 0.1rem solid var(--colors-secondary);
  background-color: var(--colors-primary);
  color: var(--colors-contrast-text-primary);
  :disabled {
    background-color: var(--color-hover-charcol) !important;
    color: var(--colors-contrast-text-primary) !important;
  }
  :hover {
    background-color: var(--colors-secondary-hover);
    color: var(--colors-contrast-text-seconday);
    border: 0.1rem solid var(--colors-primary);
  }
`;
export const Heading = ({ children, style }) => {
  return (
    <Typography
      variant="h6"
      style={{
        textAlign: "center",
        fontWeight: 600,
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};
Heading.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
export const Grid = styled(MuiGrid)(spacing);
// const Button = styled(styled(MuiButton)(spacing))`
//   background: white;
//   color: ${props => props.theme.body?.login?.btnText}
// `
export const CustomBtn = withTheme((props) => {
  const { children, theme, ...rest } = props;

  return (
    <SecondaryMUIButton theme={theme} {...rest}>
      {children}
    </SecondaryMUIButton>
  );
});
export const CustomDivider = () => {
  return (
    <Divider style={{ background: "white", width: "100%", height: ".5px" }} />
  );
};
export const DetailContainer = styled(Box)`
  width: ${(props) => props.width || "70%"};
  text-align: center;
  margin: 15px 0;
`;
export const DetailTypography = ({ children, style }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 400,
        fontSize: "16px",
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};
DetailTypography.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
export const WhiteBtn = styled(MuiButton)`
  background: white;
  color: ${(props) => props.textcolor};
  margin-left: auto;
`;
