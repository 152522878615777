import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import SimpleDialog from '../../../../SimpleDialog';
import { dialogText } from '../../../../../constants';
import { updateRouteIndex, start } from '../../../../../redux/joyride/SelfServeOnboarding/actions';
import { useDispatch, connect } from 'react-redux';
import { getRouteIndex, getSubstringId } from '../helpers';
import { onboardingStepDefinition } from '../constants';

function WelcomeMessage({ routes, showWelcomeMessage }) {
  const dispatch = useDispatch();
  /**
   * Following useEffect is setup to fire Google Ad Conversion
   * It will fire conversion event when someone lands into our page from an google ad
   */
  useEffect(() => {
    if (showWelcomeMessage) {
      if (window.gtag && window.locadui && window.locadui.GA_AD_CONVERSION_TAG && window.locadui.GA_AD_CONVERSION_ID) {
        try {
          window.gtag('config', window.locadui.GA_AD_CONVERSION_TAG);
          window.gtag('event', 'conversion', { send_to: window.locadui.GA_AD_CONVERSION_ID });
        } catch (e) {
          console.log('error in conversion tag fire', e);
        }
      }
    }
  }, [showWelcomeMessage]);

  const handleClick = () => {
    dispatch(
      updateRouteIndex({
        openRouteIndex: getRouteIndex(routes, getSubstringId(onboardingStepDefinition?.[0]?.target)),
        loading: true,
        run: false,
        continuous: false,
      })
    );
    setTimeout(() => {
      dispatch(start({ loading: false, run: true, continuous: true }));
    }, 100);
  };
  const welcomeModalBody = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          width: '115%',
        }}
      >
        <img src="/static/img/icons/onboarding_ui.svg" alt="img" style={{ width: '10rem', margin: 'auto' }} />
        <Typography variant="subtitle1" style={{ margin: '2rem auto 1rem auto' }}>
          Welcome on board!
        </Typography>
        <Typography style={{ fontSize: '1rem' }}>{dialogText.onboarding.welcome}</Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{ width: '6rem', margin: '2rem auto 0 auto' }}
          onClick={handleClick}
        >
          Start!
        </Button>
      </Box>
    );
  };
  return (
    <SimpleDialog
      {...{
        maxWidth: 'sm',
        open: showWelcomeMessage,
        fullWidth: false,
        subtitle: welcomeModalBody(),
        closeIcon: true,
        showCancelButton: false,
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return { showWelcomeMessage: state.onboarding.showWelcomeMessage };
};
export default connect(mapStateToProps, null)(WelcomeMessage);
