import React from 'react';
import styled from 'styled-components';
import { Box as MuiBox, CircularProgress } from '@material-ui/core';
import { spacing } from '@material-ui/system';

const Box = styled(MuiBox)(spacing);

function Loader({ testId = 'default-loader', minHeight = '100%', minWidth, size = 40, ...rest }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={minHeight}
      minWidth={minWidth || null}
      data-testid={testId}
      {...rest}
    >
      <CircularProgress m={2} size={size} color="secondary" data-testid="circular-progress" />
    </Box>
  );
}

export default Loader;
