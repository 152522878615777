import React from 'react';
import { SvgIcon } from '@material-ui/core';

function DollarSVG(props) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M15.0998 22.8668H17.0998V21.3668H18.6998C19.0332 21.3668 19.3054 21.2335 19.5165 20.9668C19.7276 20.7002 19.8332 20.4002 19.8332 20.0668V16.2335C19.8332 15.8779 19.7276 15.5724 19.5165 15.3168C19.3054 15.0613 19.0332 14.9335 18.6998 14.9335H14.1665V12.6335H19.8332V10.6335H17.0998V9.1335H15.0998V10.6335H13.4665C13.1332 10.6335 12.8332 10.7668 12.5665 11.0335C12.2998 11.3002 12.1665 11.6113 12.1665 11.9668V15.7668C12.1665 16.1224 12.2998 16.4057 12.5665 16.6168C12.8332 16.8279 13.1332 16.9335 13.4665 16.9335H17.8332V19.3668H12.1665V21.3668H15.0998V22.8668ZM4.6665 26.6668C4.13317 26.6668 3.6665 26.4668 3.2665 26.0668C2.8665 25.6668 2.6665 25.2002 2.6665 24.6668V7.3335C2.6665 6.80016 2.8665 6.3335 3.2665 5.9335C3.6665 5.5335 4.13317 5.3335 4.6665 5.3335H27.3332C27.8665 5.3335 28.3332 5.5335 28.7332 5.9335C29.1332 6.3335 29.3332 6.80016 29.3332 7.3335V24.6668C29.3332 25.2002 29.1332 25.6668 28.7332 26.0668C28.3332 26.4668 27.8665 26.6668 27.3332 26.6668H4.6665Z" />
      {/* </svg> */}
    </SvgIcon>
  );
}
export default DollarSVG;
