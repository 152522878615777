import Select from "@material-ui/core/Select";
import styled from "styled-components";



export const StyledSelect = styled(Select)`
  /* default */
  &.MuiInput-root {
    color: var(--colors-contrast-text-primary);
  }
  &.MuiInput-underline:before {
    border-bottom: 1px solid var(--colors-contrast-text-primary);
  }
  /* hover */
  &.MuiInput-underline:hover:before {
    border-bottom: 1px solid var(--colors-secondary-hover);
  }
  &.MuiInput-underline:not(.Mui-error):after {
    border-bottom: 1px solid var(--colors-secondary-hover);
  }
`;


  