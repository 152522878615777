import React from 'react';
import { Grid as MUIGrid, Box } from '@material-ui/core';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import ImageCarousel from './ImageCarousel';

const Grid = MUIGrid;
const GridItem = styled(MUIGrid)`
  height: 100vh;
`;
const GradientBox = styled(Box)`
  background: ${(props) => props.theme.palette.primary.main};
  height: 100vh;
  display: flex;
  color: white;
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100%;
  width: 100%;
  padding: 0 2rem;
`;

const Layout = ({ content, progressbar }) => {
  return (
    <Grid container>
      <Grid xs={6} item>
        <GradientBox>
          <FormBox>{content}</FormBox>
        </GradientBox>
      </Grid>
      <GridItem item xs={6}>
        <ImageCarousel />
      </GridItem>
    </Grid>
  );
};

Layout.propTypes = {
  content: PropTypes.node,
};

export default Layout;
