import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import styled from 'styled-components';
import { IMG_SRC } from '../../constants';

const StyledCarousel = styled(Carousel)`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 90%;
`;
const DetailContainer = styled(Box)`
  color: white;
  text-align: center;
`;

const ImageCarousel = () => {
  return (
    <StyledCarousel
      navButtonsAlwaysInvisible={true}
      indicatorIconButtonProps={{
        style: {
          color: 'white',
          fontSize: '8px',
        },
      }}
    >
      {IMG_SRC.images.map((image, index) => {
        return (
          <React.Fragment key={index}>
            <img width="100%" src={image.url} style={{ borderRadius: '20px' }} alt={image.title} />
            <DetailContainer mt={10}>
              <Typography variant="h6" style={{ fontWeight: 400 }}>
                {image.title}
              </Typography>
            </DetailContainer>
          </React.Fragment>
        );
      })}
    </StyledCarousel>
  );
};

export default ImageCarousel;
