import React from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import { Power } from "react-feather";

import { logout } from "../redux/auth/actions";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

class Header extends React.Component {
  render() {
    return (
      <Hidden mdUp data-testid="header">
        <>
          <AppBar position="sticky" elevation={0}>
            <Toolbar>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.props.onDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <>
                    <IconButton
                      onClick={this.props.logout}
                      color="inherit"
                      data-testid="log-out"
                    >
                      <Power />
                    </IconButton>
                  </>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </>
      </Hidden>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));
