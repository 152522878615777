import * as actionTypes from './actionTypes';

const initialReturnOrdersState = {
  data: null,
  filters: null,
  error: null,
  activeFilters: {
    marketplace: [],
    marketplace_status: [],
    warehouse_status: [],
    country: [],
  },
  tableState: {
    page: 0,
    rowsPerPage: 20,
    searchText: '',
    sortOrder: {},
  },
  dataLoading: true,
  dataLoaded: false,
  filtersLoading: true,
};

export default function returnOrders(state = initialReturnOrdersState, action) {
  switch (action.type) {
    case actionTypes.GET_RETURN_ORDERS:
      return {
        ...state,
        dataLoading: true,
      };
    case actionTypes.GET_RETURN_ORDERS_FILTERS:
      return {
        ...state,
        filtersLoading: true,
      };

    case actionTypes.GET_RETURN_ORDERS_SUCCESS:
      return {
        ...state,
        dataLoading: false,
        dataLoaded: true,
        data: action.response,
      };
    case actionTypes.GET_RETURN_ORDERS_FILTERS_SUCCESS:
      return {
        ...state,
        filtersLoading: false,
        filters: action.response,
      };

    case actionTypes.SET_RETURN_ORDERS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...action.payload.activeFilters,
        },
      };

    case actionTypes.SET_RETURN_ORDERS_TABLE_STATE:
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...action.payload.tableState,
        },
      };

    case actionTypes.GET_RETURN_ORDERS_FAIL:
    case actionTypes.GET_RETURN_ORDERS_FILTERS_FAIL:
      return {
        ...state,
        error: action.error,
        dataLoading: false,
        filtersLoading: false,
      };

    default:
      return state;
  }
}
