import React from 'react';
import styled from 'styled-components';
import { Button as MuiButton, Typography } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Alert } from '@material-ui/lab';
import PageHeader from './PageHeader';
import { button } from '../constants/button';
import { notifications } from '../constants';

const Button = styled(MuiButton)(spacing);

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    // You can also log error messages to an error reporting service here
    //console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // Error path
      return (
        <>
          <PageHeader title={notifications.ERROR.title} />
          <Alert severity="error" data-testid="error-alert">
            {notifications.ERROR.MAIL_TEXT}
          </Alert>
          <Typography align="center">
            <Button
              onClick={() => window.location.reload()}
              variant="contained"
              color="secondary"
              mt={6}
              data-testid="refresh-errorb-button"
            >
              {button.REFRESH_PAGE}
            </Button>
          </Typography>
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
