import { Tooltip, Typography, Box } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import React from 'react';
import { Category } from './dashboardcss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  categoryTooltip: {
    background: 'var(--colors-secondary)',
    borderRadius: '6px',
    marginLeft: '3.4rem',
    marginBottom: '-2.5rem',
  },
}));

const SidebarCategory = ({ name, icon = <Home />, ...rest }) => {
  const classes = useStyles();

  const message = (
    <Typography variant="body2" style={{ borderRadius: '5px', color: 'var(--colors-primary)' }}>
      {name}
    </Typography>
  );
  return (
    <Tooltip enterTouchDelay={0} placement="top-end" title={message} classes={{ tooltip: classes.categoryTooltip }}>
      <Category {...rest}>
        <Box activeclassname="active" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {icon}
        </Box>
      </Category>
    </Tooltip>
  );
};
export default SidebarCategory;
