import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import styled from "styled-components";

const CustomSnackbar = styled(Snackbar)`
  z-index: 1;
  width: max-content;
`;

const BannerComponent = ({
  isOpen,
  vertical,
  horizontal,
  children,
  severity,
  onBannerClose,
}) => {
  return (
    <CustomSnackbar
      open={isOpen}
      severity={severity}
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      onClose={() => onBannerClose(false)}
      data-testid="banner-component"
    >
      {children}
    </CustomSnackbar>
  );
};

export default BannerComponent;
