import { combineReducers } from 'redux';

import theme from './theme/reducer';
import sidebar from './sidebar/reducer';
import auth from './auth/reducer';
import { inventory, inventoryListing } from './inventory/reducer';
import units from './units/reducer';
import marketplaces from './marketplaces/reducer';
import warehouses from './warehouses/reducer';
import notifications from './notifications/reducer';
import confirmationDialog from './confirmationDialog/reducer';
import catalogue from './catalogue/reducer';
import consignments from './consignments/reducer';
import returnOrders from './returns/reducer';
import shipment from './shipment/reducer';
import frontDeskWidget from './frontDeskWidget/reducer';
import billing from './billing/reducer';
import onboarding from './joyride/SelfServeOnboarding/sidebarStateAndJoyrdeReducer';
import startProductTourPrompt from './joyride/StartProductTourPrompt/StartProductTourPrompt';
import { shippingProfiles, shippingProfileInfo } from './carrierManagement/reducer';
export default combineReducers({
  sidebar,
  theme,
  auth,
  inventory,
  inventoryListing,
  units,
  marketplaces,
  warehouses,
  notifications,
  confirmationDialog,
  catalogue,
  consignments,
  returnOrders,
  shipment,
  frontDeskWidget,
  billing,
  shippingProfiles,
  shippingProfileInfo,
  onboarding,
  startProductTourPrompt,
});
