/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import Layout from '../../Components/OnboardingLayout';
import MapLocationFormForBrand from '../../Components/EditLocationDetais/editLocationDetailsV2';
import OnboardingHeader from '../../Components/Onboarding/OnboardingHeader';
import { useQuery } from '../../../hooks/useQuery';
import { useAxios } from '../../../hooks/useAxios';
import { ONBOARDING_GET_LOCATIONS } from '../../../api/api';
import { createOptions } from '../../../helpers/createOptions';
import { useDispatch } from 'react-redux';
import { loadUser } from '../../../redux/auth/actions';
import { isOperableArray } from '../../../helpers/other';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.body.login?.layoutBg};
    transition: background 0.3s;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

function BrandMapping() {
  const { onboradingrequest, refer } = useQuery();
  const dispatch = useDispatch();
  const {
    data: locationsResponse,
    isLoading: locationLoading,
    callAxios: fetchLocations,
  } = useAxios({ url: ONBOARDING_GET_LOCATIONS, callOnMount: false, showError: false });
  const [address, setAddress] = useState([]);
  const [addressSelectOption, setAddressSelectOption] = useState([]);
  const [warehouseSelectOption, setWarehouseSelectOption] = useState([]);

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  useEffect(() => {
    if (onboradingrequest) {
      fetchLocations({ params: { onboarding_request: onboradingrequest } });
    }
  }, [onboradingrequest]);

  useEffect(() => {
    if (locationsResponse && locationsResponse.locations) {
      const addressOptions = createOptions(locationsResponse.locations, 'shopifyAddresses');
      if (isOperableArray(locationsResponse.warehouses)) {
        const warehouses = locationsResponse.warehouses;
        const connectedWarehouses = warehouses.filter((item) => item.connected);
        const warehouseOption = createOptions(connectedWarehouses, 'onboardingWarehouse');
        setWarehouseSelectOption(warehouseOption);
      }
      setAddress(locationsResponse.locations);
      setAddressSelectOption(addressOptions);
    }
  }, [locationsResponse]);

  return (
    <>
      <OnboardingHeader step="2" subheader="Add your default store location. You can update this information later." />
      <MapLocationFormForBrand
        loading={locationLoading}
        address={address}
        addressSelectOption={addressSelectOption}
        warehouseSelectOption={warehouseSelectOption}
        onboardingRequestId={onboradingrequest}
        refer={refer}
      />
    </>
  );
}

function LocationMapping() {
  return (
    <Root>
      <GlobalStyle />
      <CssBaseline />
      <Layout content={<BrandMapping />} />
    </Root>
  );
}

export default LocationMapping;
