/**
 * @param actionTypes
 * @type array containing 3 action types
 * @param type
 * @type string one type to generate 2 common other types
 * @description
 * You should use createAPIAction to tell the API middleware that we want to call API.
 * Creates config, parameters, handles a request, creates next action.
 */
import AxiosService from "../../../api/HttpService";
import { CALL_API, REQUEST_FAIL, REQUEST_SUCCESS } from "../../constants";
import { getActionTypes, isApiAction } from "./utils";
import { getRequestHeaders } from "../../../helpers/other";

const apiMiddleware = (store) => (next) => (action) => {
  if (isApiAction(action)) {
    const { method, url, actionTypes = [], type, body, params } = action[CALL_API];

    const [requestType, successType, errorType] = actionTypes?.length ? actionTypes : getActionTypes(type);

    requestType && next({ type: requestType });
    return AxiosService({
      method,
      url,
      data: body,
      headers: getRequestHeaders(),
      params,
    })
      .then((response) => {
        return next({
          type: successType || REQUEST_SUCCESS,
          response: response.data,
          rawResponse: response,
        });
      })
      .catch((error) => {
        return next({
          type: errorType || REQUEST_FAIL,
          error: error.response,
          rawError: error,
        });
      });
  } else {
    return next(action);
  }
};

export default apiMiddleware;
