/**
 * Use when you need to show loader inside the button
 */
import React from 'react';
import { Button as MuiButton, CircularProgress as MuiCircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
  },
}));

const WithOpacity = styled.span`
  opacity: ${(props) => (props.transparent ? 0 : 1)};
`;
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(
  spacing,
  () => `
  position: absolute;
`
);

export default function LoadingButton({ isLoading, children, color = 'primary', disabled, ...rest }) {
  const classes = useStyles();
  const buttonColors = {
    error: classes.errorButton,
  };
  return (
    <Button
      size="small"
      variant="contained"
      color={color}
      disabled={isLoading || disabled}
      className={
        !['primary', 'secondary', 'inherit'].includes(color) && buttonColors.hasOwnProperty(color)
          ? buttonColors[color]
          : ''
      }
      {...rest}
    >
      {isLoading ? <CircularProgress size={22} p={1} color="inherit" /> : null}
      <WithOpacity transparent={isLoading ? 'transparent' : null}>{children}</WithOpacity>
    </Button>
  );
}
