import { CALL_API } from "../../constants";

export const isApiAction = (action) =>
  action[CALL_API] && action[CALL_API].url && action[CALL_API].method;

// creates action that apiMiddleware can recognize
export const createAPIAction = (actionObject) => ({ [CALL_API]: actionObject });

export const getActionTypes = (type) =>
  typeof type === "string" ? [type, type + "_SUCCESS", type + "_FAIL"] : [];
export const getActionTypesObject = (type) =>
  typeof type === "string"
    ? {
        request: type,
        success: type + "_SUCCESS",
        fail: type + "_FAIL",
      }
    : {};
