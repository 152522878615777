export const listPathKey = 'listId';
export const initialListId = 'list';
export const invitetoken = 'token';
export const getListUrlFromPath = (path, listId = initialListId) => path.replace(':' + listPathKey, listId);
export const paths = {
  INDEX: '/',

  AUTH: '/auth',
  AUTH_CALLBACK: '/auth/cognito/',
  FRESHDESK_LOGIN: '/auth/freshdesk/',
  AUTH_LOGIN: '/auth/login',
  AUTH_404: '/auth/404',
  AUTH_500: '/auth/500',
  AUTH_LOGOUT: '/auth/logout',
  AUTH_LINK_BRAND: '/auth/link-brand',
  AUTH_SIGNUP: '/auth/signup',
  AUTH_EMAIL_MISMATCH_WARNING: '/auth/email-mismatch-warning',
  AUTH_EXPIRED_EMAIL_INVITE: '/auth/email-expired-invite',
  AUTH_MIGRATE_USER: '/auth/migrate-user',
  AUTH_EMAIL_INVITATION: `/auth/acceptinvitation/:${invitetoken}`,
  AUTH_NO_AVIALBLE_BRAND: '/auth/no-brand',
  AUTH_SIGNUP_CREATE_BRAND: '/auth/signup/createbrand',
  AUTH_SIGNUP_BRAND_MAPPING: '/auth/signup/mapbrand',
  AUTH_MIGRATE_USER_REDIRECT: '/auth/migrate/user/',
  AUTH_MIGRATE_USER_CONFIRMATION: '/auth/migrate/user/confirm',
  AUTH_EMAIL_INVITE_REDIRECT: '/auth/invite/cognito/',

  //Shopify Signup Routes
  AUTH_SHOPIFY_SIGNUP: '/auth/shopify/signup',
  AUTH_SHOPIFY_CREATE_USER: '/auth/shopify/createuser',
  AUTH_SHOPIFY_MAP_USER_LOCATION: '/auth/shopify/maplocation/:id',
  AUTH_SHOPIFY_HUBSPOT_USER_SIGN_IN: '/auth/shopifyhubspotuser/signin',

  //Amazon Signup Routes
  AUTH_AMAZON_SIGNUP: '/auth/amazon/signup',

  HOMEPAGE_DASHBOARD: '/homepage-dashboard',
  RETURNS_DASHBOARD: '/returns',
  SALES_OVERVIEW: '/sales/overview',
  UPSELLING_ROUTE: '/storage-and-fulfillment',
  FULFILLMENT: '/fulfillment',
  FULFILLMENT_DASHBOARD: '/fulfillment/dashboard',
  FULFILLMENT_DASHBOAARD_V2: '/fulfillment/dashboard-v2',
  FULFILLMENT_ORDERS: `/fulfillment/orders/:${listPathKey}`,
  FULFILLMENT_ORDERS_UPLOADS: '/fulfillment/order-uploads',
  FULFILLMENT_ORDER_FORECAST: `/fulfillment/forecast`,
  FULFILLMENT_CARRIER_ORDERS: `/fulfillment/shipments/:${listPathKey}`,
  FULFILLMENT_SHIPPING: `/fulfillment/shipping/:${listPathKey}`,
  FULFILLMENT_SHIPPING_CREATE_ORDER: `/fulfillment/create-shipping-order/:${listPathKey}?`,
  FULFILLMENT_PICKUP_LOCATIONS: `/fulfillment/pickup-locations/:${listPathKey}`,
  FULFILLMENT_CREATE_PICKUP_LOCATION: `/fulfillment/create-pickup-location`,

  INVENTORY: '/inventory',
  INVENTORY_DASHBOARD: '/inventory/dashboard',
  INVENTORY_PRODUCTS: `/inventory/products/:${listPathKey}`,
  INVENTORY_PRODUCT_CREATE: '/inventory/create',
  INVENTORY_CATALOGUE_LISTING: `/inventory/catalogue/:${listPathKey}`,
  INVENTORY_PRODUCT_SKU_UPLOADS: '/inventory/product_skus',

  SHIPMENTS: '/shipments',
  SHIPMENTS_DASHBOARD: '/shipments/dashboard',
  MANAGE_SHIPMENTS: `/shipments/manage/:${listPathKey}`,
  SHIPMENTS_CREATE_ORDER: `/shipments/create-shipping-order/:${listPathKey}?`,
  PENDING_PICKUPS: `/shipments/pending_pickups/:${listPathKey}`,
  PENDING_PICKUPS_LIST: `/shipments/pending_pickups/list`,
  TRACK_SHIPMENTS: `/shipments/track/:${listPathKey}`,

  PICKUP_LOCATIONS: `/pickup-locations/:${listPathKey}`,
  CREATE_PICKUP_LOCATION: `/create-pickup-location`,

  INBOUND: '/inbound',
  ADVANCE_SHIPPING_NOTES: `/inbound/ASN`,
  ADVANCE_SHIPPING_NOTES_LISTING: `/inbound/ASN/:${listPathKey}`,
  ADVANCE_SHIPPING_NOTES_UPLOAD: `/inbound/upload`,

  RETURNS_MANAGEMENT: '/returns_management',
  RETURN_ORDERS_LISTING: '/returns_management/return_orders_list',

  OUTBOUND: '/outbound',
  PULLOUTS: `/outbound/pullout`,
  PULLOUTS_LISTING: `/outbound/pullout/:${listPathKey}`,
  PULLOUTS_UPLOAD: `/outbound/upload`,
  CREATE_DISTRIBUTION_ORDER: `/outbound/create_distribution_order`,
  B2B_ORDER_DETAILS: `/outbound/b2b/review_order`,

  REPORTS: '/reports',
  GENERATE_REPORTS: '/reports/generate',
  GENERATED_REPORTS: '/reports/generated',
  SCHEDULED_REPORTS: '/reports/scheduled',

  BILLING: '/billing',
  BILLING_DASHBOARD: '/billing/dashboard',
  FULFILLMENT_BILLING: `/billing/fulfillment/:${listPathKey}`,
  RETURN_BILLING: '/billing/computation-and-rates',
  B2B_BILLING: `/billing/b2b/:${listPathKey}`,
  PACKAGING_BILLING: `/billing/packaging/:${listPathKey}`,
  STORAGE_BILLING: `/billing/storage/:${listPathKey}`,
  STORAGE_BILLING_DETAIL: `/billing/storage/id/:${listPathKey}`,
  VAS_BILLING: `/billing/vas/:${listPathKey}`,
  SHIPMENT_BILLING: `/billing/shipment`,
  RATES_BILLING: `/billing/rates/:${listPathKey}`,
  RATES_BILLING_EDIT_STORAGE: `/billing/rates/storage/edit/:id`,
  RATES_BILLING_EDIT_FULFILLMENT: `/billing/rates/fulfillment/edit/:id`,
  RATES_BILLING_EDIT_RETURN: `/billing/rates/return/edit/:id`,
  RATES_BILLING_EDIT_PULLOUT: `/billing/rates/pullout/edit/:id`,

  SETTINGS: '/settings',
  ACCOUNT_SETTINGS: '/settings/account',
  NOTIFICATION_SETTINGS: '/settings/notification',

  MARKETPLACE_SETTINGS: '/settings/marketplace',
  WAREHOUSE_SETTINGS: '/settings/warehouse',
  CARRIER_SETTINGS: '/settings/carrier',
  CARRIER_MANAGEMENT: '/settings/carrier_management',

  SUPPORT: '/support',
  HELP_CENTER: '/help',
  PRIVACY: '/privacy',
  TERMS_OF_SERVICE: '/terms-of-service',

  Wallet: `/wallet/`,

  MARKETPLACE: {
    market: {
      details: '/marketplace/market/:id/details',
    },
    Shopee: {
      authorize: '/marketplace/shopee/:id/authorize',
    },
    Lazada: {
      authorize: '/marketplace/lazada/:id/authorize',
      validate: '/marketplace/lazada/validate',
    },
    Tiktok: {
      authorize: '/marketplace/tiktok/:id/authorize',
      validate: '/marketplace/tiktok/validate',
    },
    Tokopedia: {
      authorize: '/marketplace/tokopedia/:id/authorize',
      validate: '/marketplace/tokopedia/validate',
    },
    Wix: {
      authorize: '/marketplace/wix/:id/authorize',
      validate: '/marketplace/wix/validate',
    },
    Zalora: {
      authorize: '/marketplace/zalora/:id/authorize',
    },
    Capillary: {
      authorize: '/marketplace/capillary/:id/authorize',
    },
    Custom: {
      authorize: '/marketplace/custom/:id/authorize',
    },
    Kumu: {
      authorize: '/marketplace/kumu/:id/authorize',
    },
    Bbo: {
      authorize: '/marketplace/bbo/:id/authorize',
    },
    Shopify: {
      authorize: '/marketplace/shopify/:id/authorize',
    },
    Woocommerce: {
      authorize: '/marketplace/woocommerce/:id/authorize',
    },
    Amazon: {
      authorize: '/marketplace/amazon/:id/authorize',
    },
    Ginee: {
      authorize: '/marketplace/ginee/:id/authorize',
    },
    Ebay: {
      authorize: '/marketplace/ebay/:id/authorize',
      validate: '/marketplace/ebay/validate',
    },
  },
  WAREHOUSE: {
    warehouse: {
      details: '/warehouse/wms/:id/details',
    },
    wareo: {
      authorize: '/warehouse/wareo/:id/authorize',
    },
    uniware: {
      authorize: '/warehouse/uniware/:id/authorize',
    },
    logiwa: {
      authorize: '/warehouse/logiwa/:id/authorize',
    },
    wareo3: {
      authorize: '/warehouse/wareo3/:id/authorize',
    },
  },
  CARRIER: {
    carrier: {
      details: '/carrier/:id/details',
    },
    ninjavan: {
      authorize: '/carrier/ninjavan/:id/authorize',
    },
    entrego: {
      authorize: '/carrier/entrego/:id/authorize',
    },
    xde: {
      authorize: '/carrier/xde/:id/authorize',
    },
    lalamove: {
      authorize: '/carrier/lalamove/:id/authorize',
    },
    pickupp: {
      authorize: '/carrier/pickupp/:id/authorize',
    },
    jntph: {
      authorize: '/carrier/jntph/:id/authorize',
    },
    jntsg: {
      authorize: '/carrier/jntsg/:id/authorize',
    },
    lbc: {
      authorize: '/carrier/lbc/:id/authorize',
    },
    dhl: {
      authorize: '/carrier/dhl/:id/authorize',
    },
    auspost: {
      authorize: '/carrier/auspost/:id/authorize',
    },
    asendia: {
      authorize: '/carrier/asendia/:id/authorize',
    },
    sendle: {
      authorize: '/carrier/sendle/:id/authorize',
    },
    kerry: {
      authorize: '/carrier/kerry/:id/authorize',
    },
    teleport: {
      authorize: '/carrier/teleport/:id/authorize',
    },
    singpost: {
      authorize: '/carrier/singpost/:id/authorize',
    },
  },
  LOCAD_PAYMENT: '/payment',
  LOCAD_PAYMENT_CALLBACK: '/payment/status/:paymentRequestId',
};
