import React from 'react';
import { SvgIcon } from '@material-ui/core';

function ProductSVG(props) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M1.99984 25.2665V8.8665C1.68873 8.82206 1.38873 8.59984 1.09984 8.19984C0.810948 7.79984 0.666504 7.3665 0.666504 6.89984V2.6665C0.666504 2.15539 0.866504 1.69428 1.2665 1.28317C1.6665 0.872059 2.13317 0.666504 2.6665 0.666504H25.3332C25.8443 0.666504 26.3054 0.872059 26.7165 1.28317C27.1276 1.69428 27.3332 2.15539 27.3332 2.6665V6.89984C27.3332 7.3665 27.1887 7.79984 26.8998 8.19984C26.6109 8.59984 26.3109 8.82206 25.9998 8.8665V25.2665C25.9998 25.7776 25.7943 26.2498 25.3832 26.6832C24.9721 27.1165 24.5109 27.3332 23.9998 27.3332H3.99984C3.4665 27.3332 2.99984 27.1165 2.59984 26.6832C2.19984 26.2498 1.99984 25.7776 1.99984 25.2665ZM25.3332 6.89984V2.6665H2.6665V6.89984H25.3332ZM9.99984 15.8998H17.9998V13.8998H9.99984V15.8998Z" />
      {/* </svg> */};
    </SvgIcon>
  );
}
export default ProductSVG;
