import React from 'react';
import { SvgIcon } from '@material-ui/core';

function PulloutB2BSVG(props) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="30" height="30" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M15.175 20.7722V14.9972L12.7611 17.4111L11.4472 16.0972L16.0917 11.4528L20.7361 16.0972L19.4222 17.4111L17.0083 14.9972V20.7722H15.175ZM6.83333 30C6.34444 30 5.91667 29.8167 5.55 29.45C5.18333 29.0833 5 28.6556 5 28.1667V9.83333C5 9.34444 5.18333 8.91667 5.55 8.55C5.91667 8.18333 6.34444 8 6.83333 8H25.1667C25.6556 8 26.0833 8.18333 26.45 8.55C26.8167 8.91667 27 9.34444 27 9.83333V28.1667C27 28.6556 26.8167 29.0833 26.45 29.45C26.0833 29.8167 25.6556 30 25.1667 30H6.83333ZM16 24.7139C16.8352 24.7139 17.5889 24.4745 18.2611 23.9958C18.9333 23.5171 19.5037 22.9111 19.9722 22.1778H25.1667V9.83333H6.83333V22.1778H12.0278C12.4963 22.9111 13.0667 23.5171 13.7389 23.9958C14.4111 24.4745 15.1648 24.7139 16 24.7139Z" />
      <path d="M9 7H11.7789C12.7332 7 13.3974 6.52381 13.3974 5.70448V5.63445C13.3974 4.93417 12.8859 4.66106 12.55 4.57703V4.45098C12.8783 4.35994 13.3669 4.08684 13.3669 3.40756V3.33754C13.3669 2.57423 12.7256 2.09804 11.7484 2.09804H9V2.91036H9.70236V6.18768H9V7ZM10.7101 4.11485V2.93838H11.6415C12.069 2.93838 12.3591 3.14146 12.3591 3.4916V3.56162C12.3591 3.91176 12.0614 4.11485 11.6415 4.11485H10.7101ZM10.7101 6.15966V4.95518H11.6568C12.0996 4.95518 12.3897 5.15826 12.3897 5.52241V5.59244C12.3897 5.95658 12.0919 6.15966 11.6568 6.15966H10.7101Z" />
      <path d="M14.0392 7H17.9174V6.20168H15.0393V6.17367C15.0393 5.81653 15.2225 5.61345 15.879 5.42437L16.3676 5.28431C17.2761 5.02521 17.9632 4.56303 17.9632 3.61765V3.54762C17.9632 2.60924 17.1158 2 16.0165 2C14.9171 2 14.0544 2.60924 14.0544 3.66667V3.77871H15.0316V3.65266C15.0316 3.09944 15.421 2.79832 16.0165 2.79832C16.6119 2.79832 16.986 3.09944 16.986 3.56162V3.58964C16.986 4.03782 16.673 4.33193 16.1157 4.493L15.6042 4.64006C14.5965 4.92717 14.0392 5.38936 14.0392 6.41177V7Z" />
      <path d="M18.6026 7H21.3815C22.3358 7 23 6.52381 23 5.70448V5.63445C23 4.93417 22.4885 4.66106 22.1526 4.57703V4.45098C22.4809 4.35994 22.9695 4.08684 22.9695 3.40756V3.33754C22.9695 2.57423 22.3282 2.09804 21.351 2.09804H18.6026V2.91036H19.305V6.18768H18.6026V7ZM20.3127 4.11485V2.93838H21.2441C21.6716 2.93838 21.9617 3.14146 21.9617 3.4916V3.56162C21.9617 3.91176 21.664 4.11485 21.2441 4.11485H20.3127ZM20.3127 6.15966V4.95518H21.2594C21.7022 4.95518 21.9923 5.15826 21.9923 5.52241V5.59244C21.9923 5.95658 21.6945 6.15966 21.2594 6.15966H20.3127Z" />
      {/* </svg> */};
    </SvgIcon>
  );
}
export default PulloutB2BSVG;
