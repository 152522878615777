import React from "react";
import styled from "styled-components";
import { Typography, withTheme } from "@material-ui/core";
import { rgba } from "polished";

const UpsellTextWrapper = styled(Typography)`
  font-size: ${props => props.theme.typography.body1.fontSize}px;
  padding-right: 24px;
  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    > * {
      color: black !important;
    }
    background-color: ${props => props.theme.palette.secondary.dark};
    span {
      color: ${props => props.theme.palette.secondary.contrastText};
    }
  }
`;

export const UpsellText = withTheme(({ theme }) => {
  return <UpsellTextWrapper theme={theme}>Locad Storage & Fulfillment</UpsellTextWrapper>;
});
