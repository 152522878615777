import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './MainApp';

import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';
import { addTokenToRequest } from './hoc/apiInterceptor';
import './index.css';

// unregister to use it only in charts we want
Chart.plugins.unregister(ChartDataLabels);

// Add a request interceptor
axios.interceptors.request.use(addTokenToRequest, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// const app= process.env.REACT_APP_TYPE // two accepted values are partner-hub, fulfilment

ReactDOM.render(<App />, document.getElementById('root'));
