import React from 'react';
import DomainMovedMessage from './MessgaeBody';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  background-color: white;
  align-items: center;
  border: 0.1rem solid ${(props) => props.theme.sidebar.background};
  padding: 8px 16px;
`;

const MaintenanceBanner = () => {
  return (
    <CustomBox>
      <DomainMovedMessage msg="Locad is currently under Scheduled Maintenance. We will be back online soon." />
    </CustomBox>
  );
};
export default MaintenanceBanner;
