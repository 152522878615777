// const "var(--colors-data-table-head)" = "#F0F6F4";
const activeCard = '#CFDCDC';
const overrides = {
  //pickers
  MuiPickersDay: {
    day: {
      fontWeight: '300',
    },
  },
  MuiPickersYear: {
    root: {
      height: '64px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '6px',
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: 'transparent',
      '& > *': {
        backgroundColor: 'transparent',
      },
    },
    switchHeader: {
      marginTop: '2px',
      marginBottom: '4px',
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: '32px',
      height: '32px',
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
    timeHeader: {
      '& h3': {
        fontSize: '3rem',
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      '& h2': {
        fontSize: '3.75rem',
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
  },
  //datatable overrides
  MuiTablePagination: {
    root: {
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
  },
  MUIDataTable: {
    paper: {
      borderTopLeftRadius: '.6rem',
      borderTopRightRadius: '.6rem',
      backgroundColor: 'var(--colors-data-table-head)',
    },
    responsiveBase: {
      padding: '0 1rem',
    },
  },
  MUIDataTableHead: {
    main: {
      backgroundColor: 'var(--colors-data-table-head)',
    },
  },
  MUIDataTableBodyRow: {
    root: {
      backgroundColor: 'var(--colors-data-table-head)',

      '&:hover': {
        backgroundColor: '#fff !important',
      },
    },
  },
  MUIDataTableToolbar: {
    left: {
      flex: 'none',
      marginLeft: '8px',
    },
    root: {
      justifyContent: 'space-between',
    },
    actions: {
      flex: 'none',
    },
  },
  MUIDataTableHeadCell: {
    fixedHeader: {
      backgroundColor: 'var(--colors-data-table-head)',
    },
  },
  MUIDataTableSelectCell: {
    headerCell: {
      backgroundColor: 'none',
    },
  },
  MUIDataTableBodyCell: {
    root: {
      background: 'none',
      marginLeft: 'auto',
      fontSize: '13px',
    },
  },
  MuiTableCell: {
    root: {
      padding: '0.4rem',
      fontWeight: 400,
    },
    head: {
      backgroundColor: 'none',
    },
  },
  MUIDataTableToolbarSelect: {
    title: {
      fontSize: '1rem',
    },
  },
  //list
  MuiMenuItem: {
    root: {
      margin: '0 3% 0 3%',
      borderRadius: '8px',
      '&.Mui-selected': {
        backgroundColor: activeCard,
      },
    },
  },
  MuiList: {
    root: {
      backgroundColor: '#F0F6F4',
    },
  },
  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: 'activeCard',
      },
    },
  },
  //alert
  MuiSnackBar: {
    root: {
      border: 'none',
    },
  },
  MuiAlert: {
    icon: {
      alignItems: 'center',
    },
    root: {
      padding: '12px 25px',
      fontSize: '.9rem',
    },
    standardError: { backgroundColor: '#3D3D3D', color: '#FC4F59' },
    standardSuccess: { backgroundColor: '#3D3D3D', color: '#94FFA1' },
    standardInfo: { backgroundColor: '#3D3D3D', color: '#82FFF6' },
    standardWarning: { backgroundColor: '#3D3D3D', color: '#FF875C' },
  },
  MuiDialog: {
    paper: {
      borderRadius: '1vw',
    },
  },
  //buttons
  MuiButton: {
    root: {
      padding: '6px 20px',
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: 'var(--colors-secondary-hover)',
        color: 'var(--colors-contrast-text-seconday)',
      },
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: 'var(--colors-primary)',
        color: 'var(--colors-contrast-text-primary)',
      },
    },
    sizeSmall: {
      padding: '6px 20px',
    },
  },
  MuiIconButton: {
    root: {
      '&:hover': {
        backgroundColor: 'var(--color-bg-dark-1) !important',
      },
    },
  },
  //card
  MuiCardHeader: {
    root: {
      backgroundColor: '#F0F6F4',
    },
    action: {
      marginTop: '-4px',
      marginRight: '-4px',
    },
  },
  MuiCardContent: {
    root: {
      background: '#F0F6F4',
    },
  },
  //dialog
  MuiDialogActions: {
    root: {
      display: 'inline-block',
      padding: '8px',
    },
  },
  //stepper
  MuiStepIcon: {
    root: {
      '&$completed': {
        color: '#42D48B',
      },
      '&$active': {
        color: '#5A50E2',
      },
    },
  },
  MuiStepper: {
    root: {
      padding: '0.3rem',
    },
  },
  //extras
  MuiCssBaseline: {
    '@global': {
      '*::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '*::-webkit-scrollbar-track': {
        background: 'inherit',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray',
        borderRadius: '20px',
        border: 'gray',
      },
      '*::-webkit-scrollbar-corner': {
        background: 'inherit',
      },
    },
  },
  MuiDropzoneArea: {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    invalid: {
      borderColor: 'var(--colors-secondary)',
      backgroundImage: 'repeating-linear-gradient(-45deg, #C8FFCF, #C8FFCF 25px, #50d860 25px, #50d860 50px)',
    },
    active: {
      borderColor: 'var(--colors-secondary)',
      backgroundImage: 'repeating-linear-gradient(-45deg, #C8FFCF, #C8FFCF 25px, #50d860 25px, #50d860 50px)',
    },
    text: {
      fontFamily: 'Space Grotesk',
      fontWeight: 'bold',
      fontSize: '1.1rem',
    },
  },
  MuiAccordionDetails: {
    root: {
      justifyContent: 'center',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontFamily: 'Space Grotesk',
      backgroundColor: '#6D7474',
    },
  },
  MuiChip: {
    label: {
      fontFamily: 'Space Grotesk',
    },
    labelSmall: {
      fontFamily: 'Space Grotesk',
      fontWeight: 'bold',
    },
  },
  MuiPaper: {
    root: {
      backgroundColor: 'var(--colors-data-table-head)',

      // padding: "1rem 0",
    },
    rounded: {
      borderRadius: '0.6rem',
    },
  },
  MuiSelectIcon: {
    color: 'var(--colors-contrast-text-primary)',
  },
  MuiLink: {
    root: {
      color: '#5A50E2',
    },
  },
};

export default overrides;
