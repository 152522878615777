import React from 'react';
import { Typography } from '@material-ui/core';
import { stepStatuses } from './constants';
import { onboardingStepDefinition } from './constants';

export function onboardingIsRouteOpen(index, onboarding) {
  return index === onboarding.openRouteIndex ? true : false;
}
// function to getId from routes?.[index]?.id
export function getId(name) {
  const idForTour = typeof name === 'string' ? name.split(' ')?.join('_')?.toLowerCase() : '';
  return idForTour;
}

// function to update the current step status
export function updateStepStatus(steps, currentTargetId) {
  return steps.map((step) => ({
    ...step,
    ...(step?.target?.substring(1, step.target.length) === currentTargetId ? { status: 'VISITED' } : {}),
  }));
}

// function to fetch the indexes of visited step and new step
export function fetchVisitedOrNewSteps(steps) {
  const visitedStepIndexes = [];
  const newStepIndexes = [];
  steps.forEach((step, index) => {
    if (step.status === stepStatuses.new) {
      newStepIndexes.push({
        ...step,
        index,
      });
    } else if (step.status === stepStatuses.visited) {
      visitedStepIndexes.push({
        ...step,
        index,
      });
    }
  });
  return { visitedStepIndexes, newStepIndexes };
}

// function to get id from substring if first character is #
export function getSubstringId(idString) {
  return idString.substring(1, idString.length);
}

// function to get the route index from routes dynamically
export function getRouteIndex(routes, pathname) {
  const undef = undefined;
  return routes.findIndex(
    (route) =>
      getId(route.id) === pathname ||
      (route.children ? route.children.find((childRoute) => getId(childRoute.name) === pathname) : undef)
  );
}

// function to update stepDefinition in localStorage
export function updateStepDefinitionLocally(state) {
  const stepDefinition = onboardingStepDefinition.map((step) => ({
    ...step,
    status: state.steps.find((stateStep) => stateStep.target === step.target)?.status,
  }));

  localStorage.setItem('onboardingFlow_1', JSON.stringify({ ...state, steps: stepDefinition }));
}

// OnboardingFlow_1 step definition
export function getJoyrideSteps(stepDefinition) {
  return stepDefinition.map((step) => ({
    ...step,
    target: step.target,
    content: (
      <>
        <Typography variant={step.contentTitle.variant} gutterBottom={step.contentTitle.gutterBottom}>
          {step.contentTitle.title}
        </Typography>
        <Typography variant={step.contentBody.variant}>{step.contentBody.body}</Typography>
      </>
    ),
    ...step.options,
    status: step.status,
    nextTarget: step.nextTarget,
  }));
}
