export const GET_RETURN_ORDERS = 'GET_RETURN_ORDERS';
export const GET_RETURN_ORDERS_SUCCESS = 'GET_RETURN_ORDERS_SUCCESS';
export const GET_RETURN_ORDERS_FAIL = 'GET_RETURN_ORDERS_FAIL';

export const GET_RETURN_ORDERS_FILTERS = 'GET_RETURN_ORDERS_FILTERS';
export const GET_RETURN_ORDERS_FILTERS_SUCCESS = 'GET_RETURN_ORDERS_FILTERS_SUCCESS';
export const GET_RETURN_ORDERS_FILTERS_FAIL = 'GET_RETURN_ORDERS_FILTERS_FAIL';

export const SET_RETURN_ORDERS_ACTIVE_FILTERS = 'SET_RETURN_ORDERS_ACTIVE_FILTERS';
export const SET_RETURN_ORDERS_TABLE_STATE = 'SET_RETURN_ORDERS_TABLE_STATE';
